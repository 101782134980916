import React, { useState } from 'react';
import axios from 'axios';
import './FileModal.css'; // Import the CSS file

const FileModal = ({ files, onClose, onSelect }) => {
  const [uploadMessage, setUploadMessage] = useState('');
  const [fileList, setFileList] = useState(files);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post('https://dodsego.com:5005/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setUploadMessage('File uploaded successfully');
        const newFilePath = response.data.filePath;
        console.log('newFilePath:', newFilePath);
        setFileList([...fileList, newFilePath.split('/').pop()]); // Add the new file to the list
        onSelect(newFilePath);
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadMessage('Error uploading file');
      }
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Select or Upload a File</h2>
        <input type="file" accept="image/*" onChange={handleFileUpload} />
        {uploadMessage && <p>{uploadMessage}</p>}
        <ul className="file-list">
          {fileList.map((file, index) => (
            <li key={index} onClick={() => onSelect(`${file}`)}>
              {file}
            </li>
          ))}
        </ul>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default FileModal;