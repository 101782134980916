import "./Bio.css"; // Import the external CSS file for styles
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag as faFlagNorwegian } from "@fortawesome/free-solid-svg-icons"; // Replace with appropriate icon
import { faFlag as faFlagEnglish } from "@fortawesome/free-solid-svg-icons"; // Replace with appropriate icon
import Background from "./Background";

const Bio = () => {
  const [language, setLanguage] = useState("norwegian");

  const handleToggle = () => {
    setLanguage((prevLanguage) => (prevLanguage === "norwegian" ? "english" : "norwegian"));
  };

  return (
    <div className="bio-container" id="bio">


      <div className="language-toggle">
        <FontAwesomeIcon
          icon={language === "norwegian" ? faFlagNorwegian : faFlagEnglish}
          onClick={handleToggle}
          className="flag"
        />
      </div>
      {language === "norwegian" ? (
        <div className="bioText">
          <h1 className="bio-title">Dødsego</h1>
          <p className="bio-text">
            Psykedelisk power-septett og kunstnerkollektiv fra skogens dyp.
          </p>
          <p className="bio-text">
            Dødsego henter inspirasjon fra pionerer som King Gizzard & The Lizard
            Wizard, Motorpsycho og The Doors. Opprinnelig skapt som en rockekvartett
            i Eidsvoll tilbake i 2016, har bandet siden den gang utviklet seg til et
            storband uten like. Med saksofon, en rekke gitarister og visual jockey,
            skaper de sin helt egen sound og en fargerik konsertopplevelse ulikt alt
            annet i det norske rockelandskap.
          </p>
          <p className="bio-text">
            På deres konserter kan man forvente å bli dratt med på en musikalsk
            reise med høy energi, episke, uforutsigbare låter og et altoppslukende
            visuelt kunstuttrykk. Deres syvende medlem, Julie R. Hopshaug, styrer
            det visuelle backdroppet i øyeblikket med musikken, som sammen med
            bandet skaper et eksplosivt og unikt show. Forvent heseblesende
            saksofon, kosmisk fuzz og hjertevarmende psykedelia, sammen med
            referanser til deres helter i musikk og film.
          </p>
          <h2 className="section-title">Medlemmer</h2>
                <ul className="members-list">
                  <li>Henrik Høibraaten - lead vokal, gitar</li>
                  <li>Jonas Ulleland - saksofon</li>
                  <li>Martin Haugen - bass, vokal</li>
                  <li>Nicolas Palacios – gitar, synth</li>
                  <li>Filip Melby Holm - gitar</li>
                  <li>Per Kamfjord - trommer, vokal</li>
                  <li>Julie Røbekk Hopshaug - video, videoeffekter, lysshow</li>
                </ul>
        <div className="backborderText"></div>
          
        </div>
        
      ) : (
        <div className="bioText">
          <h1 className="bio-title">Dødsego</h1>
          <p className="bio-text">
            Psychedelic power septet and artist collective from the depths of the forest.
          </p>
          <p className="bio-text">
            Dødsego draws inspiration from pioneers such as King Gizzard & The Lizard
            Wizard, Motorpsycho, and The Doors. Originally created as a rock quartet
            in Eidsvoll back in 2016, the band has since evolved into a big band like no
            other. With saxophone, a number of guitarists, and a visual jockey, they
            create their own sound and a colorful concert experience unlike anything
            else in the Norwegian rock landscape.
          </p>
          <p className="bio-text">
            At their concerts, you can expect to be taken on a musical journey with high
            energy, epic, unpredictable songs, and an all-encompassing visual art
            expression. Their seventh member, Julie R. Hopshaug, controls the visual
            backdrop in the moment with the music, which together with the band creates
            an explosive and unique show. Expect breathless saxophone, cosmic fuzz, and
            heartwarming psychedelia, along with references to their heroes in music and
            film.
          </p>
          <h2 className="section-title">Members</h2>
                <ul className="members-list">
                  <li>Henrik Høibraaten - lead vocals, guitar</li>
                  <li>Jonas Ulleland - Saxs</li>
                  <li>Martin Haugen - bass, vocals</li>
                  <li>Nicolas Palacios – guitar, synth</li>
                  <li>Filip Melby Holm - guitar</li>
                  <li>Per Kamfjord - drums, vocal</li>
                  <li>Julie Røbekk Hopshaug - video, videoeffects, lightshow</li>
                </ul>
        </div>
      )}
      <Background />
    </div>
  );
};

export default Bio;