import React from "react";
import Select from "react-select";
import "./MultiSelect.css";


const MultiSelect = ({ users, newProject, handleMembersChange }) => {
  const options = users.map((user) => ({
    value: user.username,
    label: user.username,
  }));

  const handleChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    handleMembersChange({ target: { value: values } });
  };

  return (
    <div className="multiSelect">
    <Select
      options={options}
      isMulti
      value={options.filter((option) =>
        newProject.members.includes(option.value)
      )}
      onChange={handleChange}
    />
    </div>
  );
};

export default MultiSelect;
