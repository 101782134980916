import React, { useState, useEffect } from "react";
import "./Project.css";
import axios from 'axios';
import MultiSelect from './MultiSelect';
import Select from 'react-select';


const Project = () => {
  const [projects, setProjects] = useState([]);
  const [projectStatus, setprojectStatus] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('...');
  const [newProject, setNewProject] = useState({
    title: '',
    members: [],
    createdBy: '',
    startDate: '',
    deadlineDate: '',
    status: '',
    subTodos: [],
    history: [],
    description: ''
  });
  const [newSubTodo, setNewSubTodo] = useState({ type: '', title: '', date: '', state: '' });
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [editingSubTodoIndex, setEditingSubTodoIndex] = useState(null);

  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, []);
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('...');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://dodsego.com:5005/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  const fetchProjects = async () => {
    try {
      const response = await axios.get('https://dodsego.com:5005/api/projects');
      setProjects(response.data);

    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  const fetchProject = async (_id) => {
    try {
      const response = await axios.get(`https://dodsego.com:5005/api/project/${editingProjectId}`);
      return response.data;

    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleProjectChange = (field, value) => {
    setNewProject({ ...newProject, [field]: value });
  };
  

  // Handle member selection change
  const handleMembersChange = (event) => {
    const value = Array.isArray(event.target.value)
      ? event.target.value
      : [event.target.value];
    setNewProject((prev) => ({ ...prev, members: value }));
  };
  const handleSubTodoChange = (field, value) => {
    setNewSubTodo({ ...newSubTodo, [field]: value });
  };

  const addProject = async () => {
    try {
      const response = await axios.post('https://dodsego.com:5005/api/project', newProject);
      setProjects([...projects, response.data]);
      setNewProject({
        title: '',
        members: [],
        createdBy: '',
        startDate: '',
        deadlineDate: '',
        status: '',
        subTodos: [],
        history: [],
        description: ''
      });
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const updateProject = async () => {
    console.log(editingProjectId, newProject);
    try {
      const response = await axios.put(`https://dodsego.com:5005/api/project/${editingProjectId}`, newProject);
      setProjects(projects.map(project => (project._id === editingProjectId ? response.data : project)));
      setMessage('Prosjekt oppdatert');
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      await axios.delete(`https://dodsego.com:5005/api/projects/${projectId}`);
      setProjects(projects.filter(project => project._id !== projectId));
      setMessage('Prosjekt slettet');
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const addSubTodo = (projectId) => {
    const updatedProjects = projects.map(project => {
      if (project._id === projectId) {
        project.subTodos.push(newSubTodo);
      }
      updateProject();
      setMessage('Aktivitet lagt til');
      return project;
    });
    setProjects(updatedProjects);
    setNewSubTodo({ type: '', title: '', date: '', state: '' });
  };

  const updateSubTodo = (projectId) => {
    const updatedProjects = projects.map(project => {
      if (project._id === projectId) {
        project.subTodos[editingSubTodoIndex] = newSubTodo;
      }
      return project;
    });
    setProjects(updatedProjects);
    setNewSubTodo({ type: '', title: '', date: '', state: '' });
    setEditingSubTodoIndex(null);
  };

  const deleteSubTodo = (projectId, subTodoIndex) => {
    const updatedProjects = projects.map(project => {
      if (project._id === projectId) {
        project.subTodos.splice(subTodoIndex, 1);
      }
      return project;
    });
    setProjects(updatedProjects);
  };

  const loadProjectForEditing = (project) => {
    setNewProject(project);
    setEditingProjectId(project._id);
    setprojectStatus('project');
  };

  const loadSubTodoForEditing = (projectId, subTodo, index) => {
    setNewSubTodo(subTodo);
    setEditingSubTodoIndex(index);
  };
  const projectsOption = projects.map((project) => ({
    value: project._id,
    label: project.title,
  }));
  return (
    <>
    <div className="project-management">
      {message && <p>{message}</p>}
        <section className={projectStatus === 'project' ? 'active-section' : ''}>
      <div className="act-project">
        <div className="sec1">
        <label>Prosjekttittel</label>
        <input
          type="text"
          value={newProject.title}
          onChange={(e) => handleProjectChange('title', e.target.value)}
          placeholder="Title"
        />
        <div className="">
              <div className="dateInputShort">
                <label className="margLabel" htmlFor="startDate">StartDato</label>
                <input
                  type="date"
                  id="startDate"
                  value={newProject.startDate}
                  onChange={(e) => handleProjectChange('startDate', e.target.value)}
                  placeholder="Start Date"
                />
                </div>
              <div className="dateInputShort">

                <label className="margLabel" htmlFor="dealineDate">Deadline</label>
                <input
                  type="date"
                  id="deadlineDate"
                  value={newProject.deadlineDate}
                  onChange={(e) => handleProjectChange('deadlineDate', e.target.value)}
                  placeholder="Deadline Date"
                />
                </div>
                </div>

        </div>

        <div className="sec2">
        <label htmlFor="project-members">Medlemmer</label>
      <MultiSelect
        id="project-members"
        users={users}
        newProject={newProject}
        handleMembersChange={handleMembersChange}
        />
        <div id="projectStatusBox">
          <h4>Status</h4>
          <button onClick={() => setprojectStatus("subTodos")}>SubTodos</button>
        </div>
        
        </div>
      
        <div className="sec3">
        <label htmlFor="goal">Målsetting</label>
        <textarea id="goal"
          value={newProject.goal}
          onChange={(e) => handleProjectChange('description', e.target.value)}
          placeholder="Målsetning"
        />
        <label htmlFor="method">Metode</label>
        <textarea id="method"
          value={newProject.method}
          onChange={(e) => handleProjectChange('method', e.target.value)}
          placeholder="Metode"
        />
        </div>
        <button onClick={editingProjectId ? updateProject : addProject}>
          {editingProjectId ? 'Lagre Prosjekt' : 'Lag Project'}
        </button>
        
        <button onClick={() => setprojectStatus(false)}>Til prosjektoversikt</button>
        </div>
        </section>
        <section className={projectStatus === 'subTodos' ? 'active-section' : ''}>
      <div className="subtodos">
      
      <div className="SubTodoProjectInfo">
      <h4>Aktivitetsoversikt:: {newProject.title}</h4>
      <p>Startdato: {new Date(newProject.startDate).toLocaleDateString()}</p>
      <p>Deadline: {new Date(newProject.deadlineDate).toLocaleDateString()}</p>
      </div>
      <table className="aktivitetsoversikt-table">
        <thead>
          <tr className="subTodoHead">
            <th>Type</th>
            <th>Title</th>
            <th>Date</th>
            <th>State</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newProject.subTodos.map((subTodo, index) => (
            <tr className="subTodo" key={index}>
              <td>{subTodo.type}</td>
              <td>{subTodo.title}</td>
              <td>{new Date(subTodo.date).toLocaleDateString()}</td>
              <td>{subTodo.state}</td>
              <td>
                <div className="button-group-subTodo">
                <button onClick={() => loadSubTodoForEditing(newProject._id, subTodo, index)}>
                  Edit
                </button>
                <button onClick={() => deleteSubTodo(newProject._id, index)}>Delete</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
          
          <div className="new-subtodo">
            <select value={newSubTodo.type} onChange={(e) => handleSubTodoChange('type', e.target.value)}>
              <option value="">Select Type</option>
              <option value="todo">Todo</option>
              <option value="arrangement">Arrangement</option>
            </select>
            <input
              type="text"
              value={newSubTodo.title}
              onChange={(e) => handleSubTodoChange('title', e.target.value)}
              placeholder="Title"
            />
            <label>StartDato</label>
            <input
              type="date"
              value={newSubTodo.date}
              onChange={(e) => handleSubTodoChange('date', e.target.value)}
              placeholder="Date"
            />
            <label>DeadlineDato</label>
            <input
              type="date"
              value={newSubTodo.date}
              onChange={(e) => handleSubTodoChange('date', e.target.value)}
              placeholder="Date"
            />
            <label>Status</label>
            <input
              type="text"
              value={newSubTodo.state}
              onChange={(e) => handleSubTodoChange('state', e.target.value)}
              placeholder="State"
            />
            <button onClick={() => (editingSubTodoIndex !== null ? updateSubTodo(newProject._id) : addSubTodo(newProject._id))}>
              {editingSubTodoIndex !== null ? 'Update SubTodo' : 'Add SubTodo'}
            </button>
            <button onClick={() => setprojectStatus('project')}>Prosjekt</button>
            <button onClick={() => setprojectStatus(false)}>Cancel</button>
          </div>
        
        </div>
        </section>

      <section className={projectStatus === false ? 'active-section' : ''}>
     
      <div className="projects-list">
        <table>
          <thead>
            <tr className="projectLineHead">
              <th>Title</th>
              <th>Medlemmer</th>
              <th>Start Dato</th>
              <th>Deadline Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr className="projectLine" key={project._id}>
                <td>{project.title}</td>
                <td>{project.members.join(', ')}</td>
                <td>{new Date(project.startDate).toLocaleDateString()}</td>
                <td>{new Date(project.deadlineDate).toLocaleDateString()}</td>
                <td>{project.status}</td>

                <td>
                  <button onClick={() => loadProjectForEditing(project)}>Edit</button>

                </td>
              </tr>
        ))}
        </tbody>
      </table>
    </div>
    </section>
    
    </div>
    </>
  );
};

export default Project;