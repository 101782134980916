import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const dashboardPaths = ["/dashboard", "/dashboard/web", "/dashboard/project"];

  return (
    <header className="header">
      <NavLink to="/" className="logo-link">
        <img src="../images/Fontego_Hvit_nB.png" alt="Logo" className="logo" />
      </NavLink>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          {!dashboardPaths.includes(location.pathname) &&  (
            <ul className="nav-list">
              <li><NavLink to="/music" activeClassName="active">MUSIC</NavLink></li>
              <li><NavLink to="/shows" activeClassName="active">SHOWS</NavLink></li>
              <li><NavLink to="/gallery" activeClassName="active">GALLERY</NavLink></li>
              <li><NavLink to="/bio" activeClassName="active">BIO</NavLink></li>
              <li><NavLink to="/connect" activeClassName="active">CONNECT</NavLink></li>
    
            </ul>
          )}
          {dashboardPaths.includes(location.pathname) && (
            <ul className="nav-list dashboard-nav">
              <li>JONAS:</li>
              <li><NavLink to="/dashboard" activeClassName="active">Dashboard</NavLink></li>
              <li><NavLink to="/dashboard/web" activeClassName="active">Web</NavLink></li>
              <li><NavLink to="/dashboard/project" activeClassName="active">Prosjekt</NavLink></li>
              <li><NavLink to="/dashboard/logout" activeClassName="active">LOGG UT</NavLink></li>
              <li></li>
            </ul>
          )}
      </nav>
    </header>
  );
};

export default Header;