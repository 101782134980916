import React from "react";
import "./AnimatedRoutes.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Login from "./Login";
import FrontPage from "./components/FrontPage";
import Music from "./components/Music";
import Shows from "./components/Shows";
import Gallery from "./components/Gallery";
import Bio from "./components/Bio";
import Connect from "./components/Connect";
import Register from "./Register";
import Dashboard from "./Dashboard";
import ContentEditor from "./ContentEditor";
import Logout from "./Logout";
import ProtectedRoute from './ProtectedRoute';
import Project from './components/Project';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (

        <Routes location={location}>
          <Route exact path="/" element={<FrontPage />} />
          <Route path="/music" element={<Music />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<ProtectedRoute element={ContentEditor} />} />
          <Route path="/dashboard/web" element={<ProtectedRoute element={ContentEditor} />} />
          <Route path="/dashboard/project" element={<ProtectedRoute element={Project} />} />
          <Route path="/dashboard/logout" element={<ProtectedRoute element={Logout} />} />
        </Routes>

  );
};

export default AnimatedRoutes;