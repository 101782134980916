import React, {useState, useEffect} from "react";
import "./Music.css";
import Background from "./Background";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube, faSoundcloud} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';


const Music = () => {
  const [promos, setpromos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


      // initialize
      useEffect(() => {
        // Fetch initial data
        fetchpromos();

      }, []);
    
      //Get data
      const fetchpromos = async () => {
        try {
          //const response = await axios.get('https://dodsego.com:5005/api/promo');
          const response = await axios.get('https://dodsego.com:5005/api/promo');
          setpromos(response.data);
        } catch (error) {
          console.error('Error fetching promos:', error);
        }
      };


      const getIconForLink = (link) => {
        if (link.includes('spotify')) {
          return <FontAwesomeIcon icon={faSpotify} />;
        } else if (link.includes('youtube')) {
          return <FontAwesomeIcon icon={faYoutube} />;
        } else if (link.includes('soundcloud')) {
          return <FontAwesomeIcon icon={faSoundcloud} />;
        } else if (link.includes('internal')) {
          return <FontAwesomeIcon icon={faGlobe} />;
        } else {
          return <FontAwesomeIcon icon={faGlobe} />;
        }
      };
  return (
    <>
    
      <div id="music-container">
        {promos.map((promo, index) => (
        <main key={promo._id} className={"music"+" "+"music"+index}>

            <div className="musicContainer">
              <br></br>
              <br></br>

              <div className="selectedImage">
                  <div className="linksContainer">
                    <div className="linkTitle">
                        <h1 className="">{promo.title}</h1>
                        <small className="promoDescription">{promo.description}</small>
                    </div>
                        <br></br>

                    
                    <div className="image-wrapper">
                      <img src={promo.imagePath} alt={promo.title}></img>
                    </div>
                        <br></br>

                    <div className="links-wrapper">
                      {promo.links && (
                        promo.links.map((link, index) => (
                          <div key={index} className="link-item">
                            <a href={link} target="_blank" rel="noopener noreferrer">
                              {getIconForLink(link)} 
                            </a>
                          </div>
                        )))}
                    </div>


                  </div>
               </div>

            </div>
        </main>
        ))};
        </div>
    <Background />
    
    </>
  );
  
};

export default Music;