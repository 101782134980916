import React, { useState, useEffect }  from 'react';
import './Shows.css';
import axios from 'axios';



const Shows = () => {
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState({
    type: '',
    city: '',
  });
    // initialize
    useEffect(() => {
      // Fetch initial data
      fetchEvents();
    }, []);
  //Get data
  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://dodsego.com:5005/api/events');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };
  const now = new Date();
  const upcomingEvents = events.filter(event => new Date(event.date) >= now);
  const pastEvents = events.filter(event => new Date(event.date) < now).sort((a, b) => new Date(b.date) - new Date(a.date));


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="shows-container">
      {/* Upcoming Events Section */}
      <div className="banner"></div>
      <div className="shows">
          <h2>2025</h2>
        <br />

          {upcomingEvents.length > 0 ? (
            <ul>
              {upcomingEvents.map(event => (
                <li className="calObj" key={Math.random()}>
                  <strong>{event.title}</strong>
                  <p>{formatDate(event.date)} - {event.city} - {event.venue}</p>
                  <p>{event.description}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No upcoming events.</p>
          )}

        {/* Past Events Section */}
        <br />
        <br />
        <br />
        <div className="placeholder"></div>
          <h2>2024-2023</h2>
          {pastEvents.length > 0 ? (
            <ul className="past-events-list">
              {pastEvents.map(event => (
                <li className="calObj" key={Math.random()}>
                  <strong>{event.title}</strong>
                  <p>{formatDate(event.date)} - {event.city} - {event.venue}</p>
                  <p>{event.description}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No past events.</p>
          )}
      </div>
      <div className="banner"></div>
    </div>
  );
};

export default Shows;